import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

type navBarItem = {
  name: string;
  href: string;
};

const navBarItems: navBarItem[] = [
  {
    name: 'Home',
    href: '/',
  },
  {
    name: 'News',
    href: '/blog.html',
  },
  {
    name: 'About',
    href: '/about.html',
  },
  {
    name: 'Recruitment',
    href: '/recruitment.html',
  },
  {
    name: 'Streams',
    href: '/streams.html',
  },
  {
    name: 'Roster',
    href: '/roster.html',
  },
];

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

const NavBar = (props: { current: string }) => {
  const scrollDirection = useScrollDirection();

  return (
    <Disclosure
      as="nav"
      className={`${
        scrollDirection === 'down' ? '-top-16' : 'top-0'
      } sticky h-16 bg-white shadow transition-all duration-500`}
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 justify-between">
              <div className="flex flex-shrink-0 items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {navBarItems.map((item) => {
                    return (
                      <a
                        href={item.href}
                        key={item.name}
                        className={twMerge(
                          props.current == item.href
                            ? 'border-blue-500  text-gray-900'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium',
                        )}
                      >
                        {item.name}
                      </a>
                    );
                  })}
                </div>
              </div>
              <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"></div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-4 pt-2">
              {navBarItems.map((item) => {
                return (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={twMerge(
                      props.current == item.href
                        ? 'border-blue-500 bg-blue-50 text-blue-700'
                        : 'border-transparent  text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700',
                      'block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                    )}
                  >
                    {item.name}
                  </Disclosure.Button>
                );
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
export default NavBar;
